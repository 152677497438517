var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_755_7695)" } }, [
        _c("path", {
          attrs: {
            d: "M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z",
            fill: "#FEFEFE",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M2.48069 4.69287C1.53808 5.91926 0.827311 7.33278 0.413452 8.86835H6.65617L2.48069 4.69287Z",
            fill: "#0052B4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M23.587 8.8688C23.1732 7.33327 22.4624 5.91975 21.5198 4.69336L17.3444 8.8688H23.587Z",
            fill: "#0052B4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M0.413452 15.1304C0.827359 16.6659 1.53812 18.0794 2.48069 19.3058L6.65603 15.1304H0.413452Z",
            fill: "#0052B4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M19.3055 2.47988C18.0791 1.53727 16.6657 0.826504 15.1301 0.412598V6.65527L19.3055 2.47988Z",
            fill: "#0052B4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M4.69394 21.5176C5.92033 22.4602 7.33385 23.171 8.86938 23.5849V17.3423L4.69394 21.5176Z",
            fill: "#0052B4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M8.86933 0.412598C7.3338 0.826504 5.92028 1.53727 4.69394 2.47983L8.86933 6.65522V0.412598Z",
            fill: "#0052B4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M15.1301 23.5849C16.6656 23.171 18.0791 22.4602 19.3055 21.5177L15.1301 17.3423V23.5849Z",
            fill: "#0052B4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M17.3444 15.1304L21.5198 19.3058C22.4624 18.0795 23.1732 16.6659 23.587 15.1304H17.3444Z",
            fill: "#0052B4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M23.8984 10.4348H13.5653H13.5653V0.101578C13.0529 0.034875 12.5305 0 12 0C11.4694 0 10.9471 0.034875 10.4348 0.101578V10.4347V10.4347H0.101578C0.034875 10.9471 0 11.4695 0 12C0 12.5306 0.034875 13.0529 0.101578 13.5652H10.4347H10.4347V23.8984C10.9471 23.9651 11.4694 24 12 24C12.5305 24 13.0529 23.9652 13.5652 23.8984V13.5653V13.5653H23.8984C23.9651 13.0529 24 12.5306 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348Z",
            fill: "#D80027",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M15.1304 15.1314L20.4852 20.4862C20.7315 20.24 20.9665 19.9826 21.1906 19.7158L16.6062 15.1313H15.1304V15.1314Z",
            fill: "#D80027",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M8.86954 15.1313H8.86944L3.51468 20.4861C3.76087 20.7324 4.01826 20.9673 4.28507 21.1915L8.86954 16.6069V15.1313Z",
            fill: "#D80027",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M8.86936 8.86911V8.86902L3.51455 3.51416C3.26827 3.76035 3.03333 4.01774 2.80917 4.28455L7.39369 8.86907H8.86936V8.86911Z",
            fill: "#D80027",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M15.1304 8.87029L20.4853 3.51539C20.2391 3.26911 19.9817 3.03417 19.7149 2.81006L15.1304 7.39457V8.87029Z",
            fill: "#D80027",
          },
        }),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_755_7695" } }, [
          _c("rect", { attrs: { width: "24", height: "24", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }