var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_827_1990)" } }, [
        _c("path", {
          attrs: {
            d: "M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z",
            fill: "#FFDA44",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M9.39079 10.4348H23.8979C23.1308 4.54697 18.0963 0 11.9994 0C11.1035 0 10.2307 0.0990469 9.39075 0.285234V10.4348H9.39079Z",
            fill: "#0052B4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M6.26073 10.4347V1.45898C2.96569 3.25683 0.606894 6.55453 0.10144 10.4347H6.26073V10.4347Z",
            fill: "#0052B4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M6.26068 13.5654H0.10144C0.606894 17.4456 2.96569 20.7433 6.26073 22.5411L6.26068 13.5654Z",
            fill: "#0052B4",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M9.39075 13.5655V23.715C10.2307 23.9012 11.1035 24.0002 11.9994 24.0002C18.0963 24.0002 23.1308 19.4533 23.8979 13.5654H9.39075V13.5655Z",
            fill: "#0052B4",
          },
        }),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_827_1990" } }, [
          _c("rect", { attrs: { width: "24", height: "24", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }